<template>
  <div class="container">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <modal
      name="recurrence-modal"
      class="self-recurrence-modal"
      :resizable="true"
      :height="600"
      :width="1200"
    >
      <div id="recurrence-modal" v-if="modalData && modalData.id">
        <div class="modal-header fx-main fx-end">
          <i class="fa fa-times" aria-hidden="true" v-on:click="hideModal"></i>
        </div>
        <div class="fx-main pd-2-em">
          <div class="wd-100" v-if="$store.state.user.role != 'STOREKEEPER'">
            <h2>Comprador: {{ modalData.order.storekeeper.name }}</h2>
          </div>
          <div class="fx-main">
            <h3 class="mg-RIGHT-2em">Valor da Recorrência</h3>
            <h3>{{ modalData.full_amount | toCurrency }}</h3>
          </div>
        </div>
        <div
          class="fx-main manage-product-item default-brecurrence fx-a-i-cent"
        >
          <div class="manage-product-img">
            <img :src="buildImgUrl(modalData.item)" :alt="(modalData.item.product || modalData.item.variation).name" />
          </div>
          <div class="manage-product-name">
            <span class="product-name">
              {{ modalData.item.product.name }}
              <template v-if="modalData.item.variation">&nbsp;- {{ modalData.item.variation.name }}</template>
            </span>
          </div>
          <div class="manage-supplier-name">
            <span
              class="supplier-name"
              v-if="modalData.item.product.supplier && !assumeSingleSupplier"
            >{{ modalData.item.product.supplier.name }}</span>
          </div>
          <div>
            <span>{{ modalData.item.quantity }}</span>
          </div>
          <div class="manage-product-price">
            <span
              class="product-price"
            >{{ modalData.item.unit_price | toCurrency }}</span>
          </div>
        </div>
        <div class="fx-main mg-2">Status: {{ modalData.status | toStatus }}</div>
        <a
          v-if="modalData.status === 'AWAITING_PAYMENT' &&
          $store.state.user.role === 'STOREKEEPER' &&
          modalData.payment_link"
          class="btn clickable"
          :href="modalData.payment_link"
          target="_blank"
        >Pagar Recorrência</a>
        <div v-if="modalData.item.product.supplier">
          <b>Dados de contato</b>: <br/>
          <b>Telefone</b>: {{ modalData.item.product.supplier.phone }} <br/>
          <b>Email</b>: {{ modalData.item.product.supplier.email }} <br/>
        </div>
      </div>
    </modal>
    <div class="full-width  ">
      <div class="card">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Data de Criação</th>
                <th>Data de Alteração</th>
                <th>Status</th>
                <th>Visualizar Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="recurrence in recurrences" :key="recurrence.id">
                <td>{{ recurrence.id }}</td>
                <td>{{ recurrence.created_at | formatDateTime }}</td>
                <td>{{ recurrence.updated_at | formatDateTime }}</td>
                <td>{{ recurrence.status | toStatus }}</td>
                <td class="fx fx-center clickable">
                  <i class="fas fa-search" v-on:click="showModal(recurrence.id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Recurrence from "@/services/recurrence.js";
import User from "@/services/user.js";
import util from "@/services/util";
import boolean from "boolean";

import Loading from "vue-loading-overlay";

import { buildImgUrl } from "@/services/item";

export default {
  name: "RecurrenceList",
  props: {
    recurrence_id: String
  },
  components: {
    Loading
  },
  data: () => {
    return {
      isLoading: false,
      recurrences: [],
      modalData: {},
      assumeSingleSupplier: boolean(process.env.VUE_APP_ASSUME_SINGLE_SUPPLIER),
    };
  },
  mounted() {
    this.loadRecurrences();
    if (this.recurrence_id) {
      this.showModal(this.recurrence_id);
    }
  },
  methods: {
    loadRecurrences: function() {
      this.isLoading = true;
      Recurrence.list()
        .then(recurrences => {
          this.recurrences = recurrences;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showModal: function(recurrenceId) {
      this.isLoading = true;
      Recurrence.get(recurrenceId)
        .then(recurrence => {
          this.modalData = recurrence;
          User.get(recurrence.item.product.supplier_id).then(supplier => {
            recurrence.item.product.supplier = supplier;
            this.$forceUpdate();
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.$modal.show("recurrence-modal");
    },
    hideModal: function() {
      this.$modal.hide("recurrence-modal");
    },
    buildImgUrl: function(item) {
      return buildImgUrl(item);
    },
    updateRecurrenceStatus: function(status) {
      this.isLoading = true;
      Recurrence.updateStatus(this.modalData.id, status)
        .then(
          response => {
            this.$notify({
              type: "success",
              duration : 24000,
              title: "Status Alterado",
              text: "Status foi alterado com sucesso!"
            });
            this.$forceUpdate();
          },
          errorResponse => {
            this.$notify({
              type: "error",
              duration : 24000,
              title: "Erro ao Alterar Status",
              text: util.stringifyAxiosError(errorResponse)
            });
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
import HeaderBuilder from '@/services/header-builder.js';
import axios from 'axios';
import {
    apiBaseUrl
} from "@/services/api.js";

const Recurrence = {
    list: async(searchOptions = {}) => {
        var builtSearchOpts = {};
        if (searchOptions.supplier_id) builtSearchOpts.supplier_id = searchOptions.supplier_id;
        const params = new URLSearchParams(builtSearchOpts);
        const response = await axios.get(`${apiBaseUrl}/api/recurrence?${params.toString()}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    get: async(id) => {
        const response = await axios.get(`${apiBaseUrl}/api/recurrence/${id}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    updateStatus: async(id, new_status, data = {}) => {
        const response = await axios.patch(
            `${apiBaseUrl}/api/recurrence`, {
                ... {
                    id: id,
                    sub_status: new_status
                },
                ...data
            }, {
                headers: HeaderBuilder.build()
            });
        return response.data;
    },
};

export default Recurrence;